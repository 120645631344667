.contacts-wrapper {
    height: 85%;
    scrollbar-width: thin;
    scrollbar-color: rgb(144, 0, 255) transparent;
}
.contacts-wrapper::-webkit-scrollbar {
    width: .3rem;
}
.contacts-wrapper::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background: linear-gradient(to bottom left, rgb(34, 0, 255), rgb(144, 0, 255));
}
#contact-section footer {
    display: flex;
    background: rgb(213, 36, 213);
    background: rgba(0, 0, 0, 0.5);
    height: 15%;
    position: relative;
}
#contact-section footer div {
    width: 75%;
    padding: 0 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(201, 201, 201);
}
#contact-section footer div h2 {
    font-size: clamp(1rem, 2vw, 1.3rem);
}
#contact-section footer h2 span {
    background: linear-gradient(to top right, rgba(33, 0, 180), rgba(182, 0, 233));
    background-clip: text;
    color: transparent;
}

.logout-button {
    top: 2%;
    left: 2%;
    height: 100%;
    width: 25%;
    padding: 0 .5rem;
    border-radius: .3rem 0 0 .3rem;
    font-weight: 700;
    font-size: clamp(.7rem, 2vw, 1rem);
    background: rgb(255, 0, 0);
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    #contacts-wrapper {
        height: 75%;
    }
    #contact-section footer {
        height: 25%;
        flex-direction: column;
    }
    #contact-section footer div {
        width: 100%;
        height: 50%;
    }
    .logout-button {
        width: 100%;
        height: 50%;
        border-radius: 0;
    }
}
