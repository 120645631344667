@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Red Hat Text'
}

main.dragging, main.dragging * {
  pointer-events: none;
}
main.dragging .divider {
  pointer-events: all;
} 
#home-page {
  /* display: grid;
  grid-template-columns: 35% 65%; */
  display: flex;
  min-height: 100vh;  
  position: relative;
} 

.cta {
  border: none;
  border-radius: .5rem;
  padding: .8rem 1rem;
  background: black;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) padding-box,
              linear-gradient(to bottom left, rgb(34, 0, 255), rgb(144, 0, 255)) border-box;
  border: 2px solid transparent;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.gradient-text {
  background-image: linear-gradient(to bottom left, rgb(34, 0, 255), rgb(144, 0, 255));
  background-clip: text;
  color: transparent;
}

#toggle-menu-button {
  position: absolute;
  padding: .4rem;
  border-radius: .25rem;
  top: .5%;
  left: 2%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  z-index: 10;
  display: none;
}
#toggle-menu-button svg {
  width: 100%;
  fill: rgb(106, 0, 255);
}
#contact-section {
  display: flex;
  flex-direction: column;
  width: 35%;
  background: black;
  background-image: linear-gradient(to top right, rgba(33, 0, 180, 0.2), rgba(182, 0, 233, 0.2));
}

#message-section {
  height: 100vh;
  width: 65%;
  background: linear-gradient(to bottom right, rgb(40, 40, 40), rgb(15, 15, 15));
  display: flex;
  flex-direction: column;
}

.divider {
  position: absolute;
  width: .5rem;
  height: 100%;
  top: 0;
  left: var(--left);
  background: rgba(255, 255, 255, 0.3);
  cursor: ew-resize;
  z-index: 10;
  transition: width .2s;
}
.divider:active {
  width: 1rem;
}

@media screen and (max-width: 500px) {
  #toggle-menu-button {
    display: initial;
  }
  #contact-section, #message-section {
    width: 100%;
  }
  #contact-section {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    translate: -125%;
    padding: 3rem 0 0;
    z-index: 9;
    transition: .3s;
  } 
  #contact-section.visible {
    translate: 0;
  }
  .divider {
      display: none;
  }
}