.message-banner {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    height: 9%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
}
.messages-wrapper {
    height: 82%;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding: 0 .5rem .5rem;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgb(144, 0, 255) transparent;
}
.messages-wrapper::-webkit-scrollbar {
    width: .3rem;
}
.messages-wrapper::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background: linear-gradient(to bottom left, rgb(34, 0, 255), rgb(144, 0, 255));
}
.messages-wrapper::-webkit-scrollbar-track {
    background: transparent;
}
.incoming-message, .outgoing-message {
    display: flex;
    font-size: clamp(.8rem, 2vw, 1rem);
    transition: .1s;
}
.selected {
    background: blue;
}
.incoming-message {
    justify-content: flex-start;
}
.outgoing-message {
    justify-content: flex-end;
}
.content {
    color: white;
    padding: .4rem .6rem .2rem;
    max-width: 55%;
    position: relative;
    overflow-wrap: break-word;
    overflow: hidden;
    border-radius: .8rem;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgb(0, 0, 0, 0.25)) padding-box,
                linear-gradient(to bottom left, rgb(34, 0, 255), rgb(144, 0, 255)) border-box;
    border: 2px solid transparent;
    animation: slide-up .2s cubic-bezier(.68,-0.55,.27,1.55) 1 forwards;
    transition: .2s;
}
@keyframes slide-up {
    from {
        translate: 0 30%;
        opacity: 0;
    }
    to {
        opacity: 1;
        translate: 0;
    }
}
.content::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgb(100, 34, 100, .4), rgb(100, 34, 100, .1));
    clip-path: circle(0% at var(--mouse-x) var(--mouse-y));
    transition: .3s;
}
.content:active::after {
    clip-path: circle(130%);
}
.incoming-message .content {
    border-radius: .8rem .8rem .8rem .2rem;
}
.outgoing-message .content {
    border-radius: .8rem .8rem .2rem .8rem;
}
.text-content {
    pointer-events: none;
}
/* .content:hover .time {
    opacity: 1;
} */
.time {
    display: block;
    font-size: .45rem;
    opacity: .6;
    text-align: left;
    /* opacity: 0; */
    transition: .2s;
}
/* .incoming-message .time {
    text-align: right; 
}
.outgoing-message .time {
    text-align: left;
} */

.notification {
    display: none;
}