.contact {
    width: 100%;
    /* display: flex; */
    align-items: center;
    display: grid;
    grid-template-columns: 15% 85%;
    grid-auto-flow: column;
    gap: 1rem;
    padding: .8rem 2rem .8rem 1rem;
    color: white;
    background: rgb(28, 28, 28);
    cursor: pointer;
    transition: .2s;
}
.contact.selected {
    background-image: linear-gradient(to right, rgb(70, 70, 70), rgb(35, 35, 35));
    background: #4a00bf;
}
.contact:hover {
    background: rgb(56, 56, 56);
}
.profile-picture-wrapper {
    border-radius: 20rem;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1/1;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) padding-box,
                linear-gradient(to bottom left, rgba(34, 0, 255, 0.5), rgba(144, 0, 255, 0.5)) border-box;
    border: 3px solid transparent;
    cursor: pointer;
}
.profile-picture-wrapper:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) padding-box,
                linear-gradient(to bottom left, rgba(34, 0, 255, 0.75), rgba(144, 0, 255, 0.75)) border-box;
}
.profile-picture {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    transition: .2s;
}
.user-name-and-message {
    width: 100%;
}
.username {
    font-size: clamp(.75rem, 2vw, 1rem);
    font-weight: 500;
}
.last-message {
    font-size: clamp(.7rem, 2vw, .9rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}