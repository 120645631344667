#sign-up-page {
	min-height: 100vh;
	display: grid;
	justify-items: center;
	align-items: center;
	background-image: linear-gradient(to top right, rgb(10, 10, 10), rgb(45, 45, 45));
}
.form-wrapper {
	width: 90%;
	height: 90%;
	display: grid;
	overflow: hidden;
	align-items: center;
	border-radius: 2rem;
	grid-auto-flow: column;
	grid-auto-columns: 100%;
	padding: .5rem 0;
	background-image: linear-gradient(to top right, rgb(33, 0, 180), rgba(182, 0, 233, 0.8));
	background-size: 200%;
	box-shadow: 0rem 0rem 20rem rgba(33, 0, 180, 0.2), 0rem 0rem 20rem rgba(182, 0, 233, 0.2);
	animation: fade-in 1s cubic-bezier(.68,-0.55,.27,1.55) 1 forwards;
	scroll-snap-type: inline mandatory;
	transition: .4s;
}
@keyframes fade-in {
	from {
		scale: .85;
		filter: blur(10px)
	}
}
@keyframes gradient-bg {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}

#sign-up-page form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 1rem;
	scroll-snap-align: center;
	transition: .75s cubic-bezier(.68,-0.55,.27,1.55);
}
input {
	padding: .5rem 1rem;
	font-size: 1rem;
	border: 1px solid rgba(255, 255, 255, 0.5);
	width: 80%;
	color: white;
	background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)) padding-box,
	linear-gradient(to bottom left, rgb(34, 0, 255), rgb(144, 0, 255)) border-box;
  	border: 2px solid transparent;
	border-radius: .4rem;
}
#sign-up-page input{
	max-width: 25rem;
}
input::placeholder {
	font-weight: 500;
}
input:focus {
	outline: none;
}
.hint {
	font-weight: 500;
	text-align: center;
	font-size: clamp(.85rem, 2vw, 1rem);
}
.hint span {
	color: transparent;
	background: linear-gradient(to top left, rgb(69, 125, 255), rgb(163, 125, 255));
	background-clip: text;
	cursor: pointer;
}