#welcome-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;
}
#welcome-section svg {
    width: 90%;
    max-width: 8rem;
    fill: rgb(144, 0, 255);
}
/* img {
    width: 90%;
    max-width: 10rem;
} */